<template>
  <div class="h-screen w-full bg-white">
    <auth-logo-banner/>

    <div class="vx-row no-gutter w-full items-center justify-center pb-base bg-white">
      <div class="vx-col w-full sm:w-8/12 md:w-6/12 lg:w-4/12 py-base">
        <vx-card no-shadow card-border>
          <div slot="no-body">
            <div class="vx-row no-gutter justify-center items-center p-8">
              <div class="vx-col w-full">
                <div class="vx-card__title">
                  <h4 class="mb-4 text-center lg:text-left">
                    {{ $t('TwoFactorAuthentication') }}
                  </h4>
                </div>

                <vs-divider></vs-divider>

                <form @submit.prevent>
                  <vs-input
                    v-model="code"
                    class="w-full mt-0"
                    :name="$t('TwoFactorAuthenticationCode')"
                    :label="$t('TwoFactorAuthenticationCode')"
                    icon-no-border
                    icon="icon icon-lock"
                    icon-pack="feather"
                    v-validate="'required|min:6'"
                    :danger="codeHasErrors"
                    :danger-text="codeErrorMsg"
                    val-icon-danger="clear"
                    @input="codeError = ''"
                    @keyup.enter="verifyCode()"
                  />

                  <div class="mt-1">
                    <small class="text-justify">
                      <em>
                        {{ $t('TwoFactorAuthenticationMsg') }}
                      </em>
                    </small>
                  </div>

                  <div class="flex flex-wrap-reverse justify-between items-center mt-base">
                    <vs-button
                      type="border"
                      to="/login"
                      class="w-full lg:w-auto">
                      {{ $t('$Auth.BackToLogin' )}}
                    </vs-button>

                    <vs-button
                      class="w-full lg:w-auto mb-5 lg:mb-0"
                      @click="verifyCode()">
                      {{ $t('VerifyCode') }}
                    </vs-button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import AuthLogoBanner from '@/views/pages/auth/components/AuthLogoBanner.vue';

/**
 * Component second step login two factor authentication
 *
 * @module views/pages/auth/TheAuthSecondStep.vue
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {string} [code=''] - The code two factor authentication
 * @vue-event {void} verifyCode - verify the 2FA code entered
 */
export default {
  name: 'TheAuthSecondStep',
  i18n: {
    messages: {
      en: {
        TwoFactorAuthentication: 'Two-Factor Authentication',
        TwoFactorAuthenticationCode: 'Two-factor authentication code',
        TwoFactorAuthenticationMsg: 'Enter the code from the two-factor app on your mobile device. If you\'ve lost your device, you may enter with your backup code.',
        VerifyCode: 'Verify code',
      },
    },
  },
  components: {
    AuthLogoBanner,
  },
  data() {
    return {
      code: '',
      codeError: '',
    };
  },
  computed: {
    codeHasErrors() {
      return this.errors.has(this.$t('TwoFactorAuthenticationCode')) || !!this.codeError;
    },
    codeErrorMsg() {
      return this.errors.first(this.$t('TwoFactorAuthenticationCode')) || this.codeError;
    },
  },
  methods: {
    ...mapActions({
      loginSecondStep: 'auth/loginSecondStep',
    }),
    async verifyCode() {
      const formIsValid = await this.$validator.validate();

      if (!formIsValid) {
        document.getElementsByName([this.$validator.errors.items[0].field])[0].focus();
        return;
      }

      this.$vs.loading({ type: 'radius' });

      try {
        await this.loginSecondStep({
          code: this.code,
          token: this.$route.query.tokenSecondStep,
        });

        await this.$router.push({ name: 'home' });

        this.$showSuccessActionNotification({
          title: this.$t('$Auth.LoginAttempt'),
          text: this.$t('$Auth.SuccessfullyLoginMsg'),
        });
      } catch (e) {
        if (e.response && e.response.data
          && e.response.data.invalidCode) {
          this.codeError = e.response.data.message;
        } else {
          throw e;
        }
      } finally {
        this.$vs.loading.close();
      }
    },
  },
};
</script>
